import { goTo_geolocation } from '@lib/helperFunctions';
import { Close } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  Modal,
  Typography,
  useMediaQuery,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AngebotContext } from '../context/AngebotContext';
import { MapContext } from '../context/MapContext';
import { createPopups } from '../lib/create-popups';
import { useDebounce } from '../lib/useDebounce';
import { Colours } from '../theme';
import { katsWithoutDetails } from './Filter/FilterComponent';
import Tooltip from './Filter/Tooltip';
import SearchResultTable, {
  SEARCH_RESULT_TABLE_WIDTH,
  TOP_OFFSET,
} from './SearchResultTable';
import { MEDIA } from './Ui';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0px 8px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    position: 'relative',
    padding: 6,
    flex: 1,
  },
  search: {
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    left: '16',
    background: '#fff',
    color: 'rgb(0, 178, 176)',
    border: '1px solid rgb(0, 178, 176)',
    padding: '5px 10px',
    borderRadius: '24px',
    width: '90%',

    '& .MuiTypography-body1 ': {
      fontSize: '14px',
      paddingTop: '4px',
    },

    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },

  searchCloseButton: {
    width: '12px',
    padding: 0,
    color: 'var(--dark-green)',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default function Search({ angebotFilter, handleDrawerClose }) {
  const [showNationals, setShowNationals] = useState(false);

  const [searchModal, setSearchModal] = useState(false);

  const { map } = useContext(MapContext);
  const [searchTerm, setSearchTerm] = useState('');
  const isMobile = useMediaQuery(MEDIA);

  const { root, input, iconButton, search, searchCloseButton } = useStyles();
  const { mapData, setMapData } = useContext(AngebotContext);

  const { angebot, searchValue } = mapData || {};
  const debouncedValue = useDebounce(searchValue, 300);

  const handleSearchSelection = (guid) => {
    if (guid) {
      axios
        .get(process.env.REACT_APP_API_URL + '/Angebote/angebot/' + guid)
        .then((resp) => {
          let koords = {
            lat: resp.data.latitude,
            lng: resp.data.longitude,
          };

          setSearchTerm(resp.data.name);

          // create popups according to search result
          // needed to create a new icon for the selected search result
          createPopups({ map: map, data: angebot, searchResult: guid });

          // close leaflet popup
          // we need to close the popup before we can goTo_geolocation() because popups are kept in view
          map.closePopup();

          goTo_geolocation(map, koords);
          setMapData({ ...mapData, searchValue: '' });
        });
      setMapData({ ...mapData, searchValue: '' });
    }
  };

  const reset = (guid) => {
    handleSearchSelection(guid);
    handleDrawerClose();
    setMapData({ ...mapData, searchData: [] }); // reset data
  };

  const onKeyUp = (event) => {
    // enter key
    if (event.key === 'Enter') {
      event.preventDefault();
    } else if (event.key === 'Escape') {
      // esc key
      event.preventDefault();
      setMapData({ ...mapData, searchValue: '' });
    }
  };

  const inputHandler = (event) => {
    const search_term = event.target.value ?? '';
    setMapData({ ...mapData, searchValue: search_term });
  };

  useEffect(() => {
    const actualKatWas = angebotFilter?.kat_Was?.filter((kat) =>
      katsWithoutDetails.includes(kat)
    );

    const filter = { ...angebotFilter, kat_Was: actualKatWas ?? [] };

    const payload = {
      searchString: debouncedValue,
      angebotFilter: { ...filter },
    };

    if ((debouncedValue && debouncedValue.length >= 3) || showNationals) {
      setSearchModal(true);
      axios
        .post(
          process.env.REACT_APP_API_URL +
            '/angebote/angebotmapviewmodelscount/',
          payload
        )
        .then((resp) => {
          getDataFromResp(resp.data, payload);
        });
    }

    getDataFromResp(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, angebotFilter, showNationals]);

  const [loading, setLoading] = useState(false);

  const getDataFromResp = useCallback(
    (count, value) => {
      setLoading(true);
      if (count !== 0) {
        axios
          .post(
            process.env.REACT_APP_API_URL + '/angebote/angebotmapviewmodels/',
            { ...value, take: count }
          )
          .then((resp) => {
            const angeboteToShow = resp.data.filter((angebot) =>
              showNationals ? angebot.einsDeutschlandweit : true
            );

            setMapData({ ...mapData, searchData: angeboteToShow });
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
          });
      } else {
        setMapData({ ...mapData, searchData: [] });
        setLoading(false);
      }
    },
    [mapData, setMapData, showNationals]
  );

  if (!map) return null;

  const style = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    border: '1px solid #ccc',
  };

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Paper component='form' className={root}>
        <InputBase
          type='search'
          className={input}
          placeholder='Suche'
          inputProps={{ 'aria-label': 'suche' }}
          onChange={inputHandler}
          onKeyDown={onKeyUp}
          value={searchValue ?? ''}
        />

        {searchTerm ? (
          <Box className={search}>
            <Typography noWrap>{searchTerm}</Typography>
            <IconButton
              onClick={() => {
                setSearchTerm('');

                //Create popups for all angebote again when search is reset
                // Needed to recluster the markers
                createPopups({ map: map, data: angebot });

                // close leaflet popup because clusters are back and popup might not represent the correct marker
                map.closePopup();
              }}
              className={searchCloseButton}
              aria-label='search-close'
              size='large'
            >
              <Close />
            </IconButton>
          </Box>
        ) : (
          <>
            <IconButton
              className={iconButton}
              onClick={() => setMapData({ ...mapData, searchValue: '' })}
              aria-label='search'
              size='large'
            >
              {!!searchValue && (
                <Close color='primary' fontSize='small' mr={1} />
              )}
            </IconButton>
            <Box color={Colours._textGrey} display='flex' alignItems='center'>
              <SearchIcon />
            </Box>
          </>
        )}
      </Paper>
      <Box component='label' display='flex' alignItems='center' mt={0.1}>
        <Checkbox
          checked={showNationals}
          onChange={(e) => {
            const checked = e.target.checked;
            setShowNationals(checked);
          }}
          sx={{
            padding: '0',
            color: 'white',

            '&.Mui-checked': {
              color: 'white',
            },
          }}
          {...(isMobile
            ? {
                size: 'small',
              }
            : {})}
        />
        <Box
          component='span'
          color='white'
          ml={0.2}
          mt={0.8}
          sx={{
            ...(isMobile
              ? {
                  '&.MuiBox-root': {
                    fontSize: '.8rem',
                  },
                }
              : {}),
          }}
          {...(isMobile ? { fontSize: '.8rem' } : {})}
        >
          deutschlandweit
        </Box>
        <Suspense fallback={<div>..</div>}>
          <Tooltip name={'info'} color='white'>
            Nur die Hilfsangebote anzeigen, die deutschlandweit nutzbar sind.
            Also z.B. Online-Beratung, Telefon-Hotline.
          </Tooltip>
        </Suspense>
      </Box>
      {loading ||
      (mapData?.searchData && !!searchValue) ||
      (mapData?.searchData && showNationals) ? (
        <Modal
          open={!isMobile} // open modal only on desktop
          onClose={() => {
            if (!isMobile) {
              setSearchModal(false);
            } else {
              setMapData({ ...mapData, searchData: [], searchValue: '' });
            }
          }}
          aria-labelledby='such-ergebnisse'
          aria-describedby='angebot-suche-ergebnisse'
          sx={{
            left: '360px',
            maxWidth: 'fit-content',
            '.MuiModal-backdrop': {
              left: '360px',
              backgroundColor: 'transparent',
              width: !searchModal ? '0' : '100%', //prevent backdrop from covering the map when search results are hidden
            },
          }}
        >
          <>
            <Box sx={style}>
              {searchModal && (
                <SearchResultTable
                  tabIndex='1'
                  data={mapData?.searchData}
                  clickHandler={reset}
                  searchTerm={searchValue}
                  showNational={showNationals}
                  loading={loading}
                />
              )}
            </Box>
            <Button
              onClick={() => {
                setSearchModal((prev) => !prev);
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                background: 'white',
                position: 'absolute',
                border: '1px solid #ccc',
                borderRadius: '0',
                minWidth: 'fit-content',
                padding: 0,
                width: 16,
                height: 24,
                justifyContent: 'center',
                left: searchModal ? SEARCH_RESULT_TABLE_WIDTH : 0, // width of search result table
                rotate: !searchModal ? '180deg' : '0',
                top: TOP_OFFSET + 24,
                '&:hover': {
                  background: 'white',
                },
              }}
            >
              <svg
                width='5'
                height='10'
                viewBox='0 0 8 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0.390472 5.15244C-0.130157 5.62099 -0.130157 6.38193 0.390472 6.85048L5.72172 11.6485C6.10491 11.9933 6.67552 12.0945 7.17532 11.9071C7.67513 11.7197 8 11.2849 8 10.7976V1.20159C8 0.718038 7.67513 0.279471 7.17532 0.0920491C6.67552 -0.0953727 6.10491 0.00958326 5.72172 0.350691L0.390472 5.14869V5.15244Z'
                  fill='#666D7E'
                />
              </svg>
            </Button>
          </>
        </Modal>
      ) : null}
      {(isMobile && searchValue) || (isMobile && showNationals) ? (
        <SearchResultTable
          tabIndex='1'
          data={mapData?.searchData}
          clickHandler={reset}
          searchTerm={searchValue}
          showNational={showNationals}
          loading={loading}
          closeResults={() => {
            setMapData({ ...mapData, searchData: [], searchValue: '' });
            setShowNationals(false);
          }}
        />
      ) : null}
    </Box>
  );
}
