import React, { useEffect } from 'react';

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import useStyles from './Ui-styles';
import { useMediaQuery } from '@mui/material';
import FilterComponent from './Filter/FilterComponent';
import { MapContext } from '../context/MapContext';
import { CustomButton } from './common/PopupButton';
import { Colours } from '../theme';

export const MEDIA = '(max-width: 959px)';

export default function UI() {
  const classes = useStyles();

  const isMobile = useMediaQuery(MEDIA);
  const [open, setOpen] = React.useState();
  const { map } = React.useContext(MapContext);

  useEffect(() => {
    /* close sidebar on small devices */
    setOpen(!isMobile);
  }, [isMobile]);

  function handleClose() {
    const mobile = window.matchMedia(MEDIA).matches;
    mobile && setOpen(false);
  }

  return (
    <div id='overlay' className={classes.root}>
      {/* id="overlay" needed to stop mouse propagation  in App.js */}
      <CssBaseline />

      {isMobile && (
        <div style={{ position: 'absolute', zIndex: 10000, margin: '16px' }}>
          <CustomButton
            style={{
              display: open ? 'none' : 'block',
              position: 'relative',
              backgroundColor: Colours._darkGreen,
              color: Colours._lightGrey,
            }}
            onClick={() => setOpen(true)}
          >
            Filter
          </CustomButton>
        </div>
      )}
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={open}
        classes={{ paper: classes.drawerPaper }}
        id='ui'
        style={{ display: isMobile && !open ? 'none' : 'block' }}
      >
        {isMobile && (
          <CustomButton
            sx={{
              marginBottom: '16px',
              color: Colours._lightGrey,
              borderColor: Colours._lightGrey,
            }}
            onClick={() => setOpen(false)}
          >
            Filter schliessen
          </CustomButton>
        )}
        <FilterComponent
          map={map}
          handleDrawerClose={handleClose}
          isMobile={isMobile}
        />
      </Drawer>
    </div>
  );
}
