import makeStyles from '@mui/styles/makeStyles';
import { Colours } from '../theme';

// var(--dark-green) grün
// #D8DC40 hell grün
// #FF6920 orange

const drawerWidth = '360px';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontFamily: 'TradeGothic',
    // position: 'relative',
  },
  title: {
    color: '#d5e300',
    fontSize: '25px',
    fontWeight: 600,
    lineHeight: '1.2em',
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {},
    '& a': { color: '#d5e300' },
  },

  appBar: {
    background: Colours._deepGreen,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // #409ba2 #c6e1ea
    top: 0,
    left: 0,
    right: 'auto',
    position: 'fixed',
    paddingBottom: '6px',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
  },
  drawer: {
    width: drawerWidth,
    position: 'absolute',
    left: '0',
    flexShrink: 0,
    zIndex: '1100',
    [theme.breakpoints.down('sm')]: {
      marginTop: '12px',
    },
  },
  closed: {
    display: 'none',
  },
  drawerPaper: {
    background: Colours._deepGreen,
    maxWidth: drawerWidth,
    minWidth: drawerWidth,
    maxHeight: '85vh',
    boxShadow:
      '0px 4px 4px -1px rgb(0 0 0 / 20%), 0px 13px 5px 0px rgb(0 0 0 / 14%), 0px 9px 10px 0px rgb(0 0 0 / 12%)',
    padding: '.7rem',
    position: 'relative',
    overflow: 'hidden',
  },
  drawerHeader: {
    alignItems: 'center',
    color: '#fff',
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    '& > span': { justifyContent: 'flex-start' },
    '&:hover': {
      background: 'transparent',
    },
  },
  drawerHeader2: {
    background: '#fff',
    position: 'absolute',
    left: '100%',
  },
  link: {
    color: 'var(--dark-green)',
    textDecoration: 'none',
  },
  btn: {
    fontFamily: 'TradeGothic',
    color: 'rgba(0, 0, 0, 0.67)',
    '& span': {
      position: 'relative',
      top: '3px',
      left: '-1em',
    },
  },

  privacy: {
    color: '#fff',
    fontSize: '12px',
    fontWeight: 600,
    padding: 0,
    maxWidth: 'fit-content',
    marginRight: '1rem',
    '& span': {
      textTransform: 'capitalize',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
}));

export default useStyles;
